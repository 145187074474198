import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '..';

export default async function SkillsLoader(options?: { params?: any }) {
	
	if (options?.params.skill) {
		const functions = getFunctions(app, 'europe-west3');
		const getAboutMe = httpsCallable(functions, 'getSkill')
		
		const { data } = await getAboutMe({ skill: options.params.skill });

		return data;
	}

	const functions = getFunctions(app, 'europe-west3');
	const getAboutMe = httpsCallable(functions, 'getSkills')
	
	const { data } = await getAboutMe();

	return data;
}