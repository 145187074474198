import { useRouteError } from "react-router"

export default function ErrorPage() {

	const error: any = useRouteError();
	console.error(error);

	return (
		<div style={{width: '100vw', height: '100vh'}}>
			<h1>{error.statusText || error.message}</h1>
		</div>
	)
}