import { Link, useLoaderData } from "react-router-dom";
import { functions } from "../..";
import { httpsCallable } from "firebase/functions";

export default function EducationAdmin() {

	const { education } = useLoaderData() as { education: any[] };

	async function deleteEducation(education: string) {
		await httpsCallable(functions, 'deleteEducation')({
			educationId: education
		});
	}

	return (
		<div className="container">
			<h1>Education</h1>

			<Link className="spanButton" to="/admin/education/add">Add education</Link>

			<table>
				<thead>
					<tr>
						<th>Title</th>
						<th>Education institution</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{education.map((edu: any) => (
						<tr key={edu.id}>
							<td>{edu.title}</td>
							<td>{edu.institution.name}</td>
							<td>
								<a className="spanButton" onClick={() => deleteEducation(edu.id)}>Delete</a>
								<Link className="spanButton" to={`/admin/education/${edu.id}`}>Edit</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}