import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '..';

export default async function SocialLinksLoader() {
	
	const functions = getFunctions(app, 'europe-west3');
	const getAboutMe = httpsCallable(functions, 'getSocials')
	
	const { data } = await getAboutMe();

	return data;
}