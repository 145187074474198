import { Link, useNavigation } from "react-router-dom";
import "./Sidebar.css";
import { auth } from "../..";

export default function Sidebar() {

	const { location, state } = useNavigation();
	
	return (
		<div className="sidebar">

			<div className="sidebarItem">
				<h3 className="sidebarMainTitle">Admin</h3>
			</div>

			<div className="sidebarItem">
				<h4 className="sidebarSubtitle">{auth.currentUser?.email ?? "No email"}</h4>
			</div>

			<div className="sidebarItem">
				<Link to="/admin/about" className="sidebarItemText">About Me</Link>
				<span className="spacer"></span>
				<div className={"loader" + (location?.pathname === "/admin/about" && state === "loading" ? " loading" : "")}></div>
			</div>
			<div className="sidebarItem">
				<Link to="/admin/skills" className="sidebarItemText">Skills and technologies</Link>
				<span className="spacer"></span>
				<div className={"loader" + (location?.pathname === "/admin/skills" && state === "loading" ? " loading" : "")}></div>
			</div>
			<div className="sidebarItem">
				<Link to="/admin/work" className="sidebarItemText">Work experiences</Link>
				<span className="spacer"></span>
				<div className={"loader" + (location?.pathname === "/admin/work" && state === "loading" ? " loading" : "")}></div>
			</div>
			<div className="sidebarItem">
				<Link to="/admin/education" className="sidebarItemText">Education</Link>
				<span className="spacer"></span>
				<div className={"loader" + (location?.pathname === "/admin/education" && state === "loading" ? " loading" : "")}></div>
			</div>
			<div className="sidebarItem">
				<Link to="/admin/projects" className="sidebarItemText">Projects</Link>
				<span className="spacer"></span>
				<div className={"loader" + (location?.pathname === "/admin/projects" && state === "loading" ? " loading" : "")}></div>
			</div>

			<div className="sidebarItem spacer">
			</div>

			<div className="sidebarItem">
				<div onClick={() => auth.signOut()} className="sidebarItemText signOutButton">Sign out</div>
			</div>
		</div>
	)
}