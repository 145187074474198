import { formatDate } from "..";
import { BulletPointList } from "../BulletPoint";

export default function Work({ work }: { work: any }) {
	return (
		<div className="experience">
			<h3><a href={work.company.link} target="_blank">{work.company.name}</a> | {work.title}</h3>

			<h6>
				{
					work.duration.current
					? <>Since <span className="experienceTime">{formatDate(work.duration.start)}</span></>
					: <><span className="experienceTime">{formatDate(work.duration.start)}</span> - <span className="experienceTime">{formatDate(work.duration.end)}</span></>
				}
			</h6>

			<BulletPointList bulletPoints={work.bodyBulletPoints} />
		</div>
	)
}