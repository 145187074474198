import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '..';

export default async function WorkLoader(options?: { params?: any }) {
	
	if (options?.params.work) {
		const functions = getFunctions(app, 'europe-west3');
		const getAboutMe = httpsCallable(functions, 'getWorkExperience')
		
		const { data } = await getAboutMe({ workId: options.params.work });

		return data;
	}

	const functions = getFunctions(app, 'europe-west3');
	const getAboutMe = httpsCallable(functions, 'getWorkExperiences')
	
	const { data } = await getAboutMe();

	return data;
}