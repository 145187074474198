import { formatDate } from ".."
import { BulletPointList } from "../BulletPoint"

export default function Education({ education }: { education: any }) {

	return (
		<div className="experience">
			
			<h3 style={{"fontSize": "24px"}}><a target="_blank" href={education.titleLink}>{education.title}</a>{
				education.graduation ? <> | {education.graduation.title} ({education.graduation.grade})</> : <></>
			}</h3>

			<h6><span className="experienceTime">{formatDate(education.duration.start)}</span> - <span className="experienceTime">{formatDate(education.duration.end)}</span></h6>

			<BulletPointList bulletPoints={education.bodyBulletPoints} />

		</div>
	)
}