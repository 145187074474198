import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { Link, useLoaderData } from "react-router-dom"
import { functions } from "../..";
import "./Skills.css"

const sortOptions = [
	{ value: 'skill', label: 'Skill' },
	{ value: 'proficiency', label: 'Proficiency' },
	{ value: 'years', label: 'Years of Experience' }
];

export default function SkillsAdmin() {

	const { skills } = useLoaderData() as { skills: any[] };

	const [skillsList, setSkillsList] = useState(skills);

	const [sort, setSort] = useState('skill');
	const [sortDirection, setSortDirection] = useState('asc');

	function handleSkillsChange(e: any) {
		const skills = e.target.value;
		setSkillsList(skills);
	}

	async function deleteSkill(skill: string) {
		await httpsCallable(functions, 'deleteSkill')({
			skill
		});
	}

	return (
		<div className="container">
			<h1>Skills</h1>

			<Link className="spanButton" to="/admin/skills/add">Add skill</Link>

			<table>
				<thead>
					<tr>
						<th className="clickable" onClick={() => {
							setSort('skill');
							setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
						}}>
							Skill {sort === 'skill' && (
								<span className="sortDirection">{sortDirection === 'asc' ? '▲' : '▼'}</span>
							)}
						</th>
						<th className="clickable" onClick={() => {
							setSort('proficiency');
							setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
						}}>
							Proficiency {sort === 'proficiency' && (
								<span className="sortDirection">{sortDirection === 'asc' ? '▲' : '▼'}</span>
							)}
						</th>
						<th className="clickable" onClick={() => {
							setSort('years');
							setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
						}}>
							Years of Experience {sort === 'years' && (
								<span className="sortDirection">{sortDirection === 'asc' ? '▲' : '▼'}</span>
							)}
						</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{skillsList.sort((a: any, b: any) => {
						if (sortDirection === 'asc') {
							if (sort === 'years') {
								if (a[sort] === b[sort]) {
									return a.skill.localeCompare(b.skill);
								} else {
									return a[sort] - b[sort];
								}
							} else if (sort === 'proficiency') {
								if (a[sort] === b[sort]) {
									return a.skill.localeCompare(b.skill);
								} else {
									return a[sort] - b[sort];
								}
							} else {
								return a[sort].localeCompare(b[sort]);
							}
						} else {
							if (sort === 'years') {
								if (a[sort] === b[sort]) {
									return a.skill.localeCompare(b.skill);
								} else {
									return b[sort] - a[sort];
								}
							} else if (sort === 'proficiency') {
								if (a[sort] === b[sort]) {
									return a.skill.localeCompare(b.skill);
								} else {
									return b[sort] - a[sort];
								}
							} else {
								return b[sort].localeCompare(a[sort]);
							}
						}
					}).map((skill: any) => (
						<tr key={skill.skill}>
							<td>{skill.skill}</td>
							<td>{skill.proficiency}</td>
							<td>{skill.years}</td>
							<td>
								<span className="spanButton" onClick={() => deleteSkill(skill.skill)}>Delete</span>
								<Link className="spanButton" to={`/admin/skills/${skill.skill}`}>Edit</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}