import Education from "./Education"

export default function EducationList({ educations }: { educations: any }) {
	return (
		<div className="innerContainer">
			<h1>Education</h1>

			<div className="experienceContainer">
				{educations.map((education: any) => {
					return (
						<Education education={education} />
					)
				})}
			</div>
		</div>
	)
}