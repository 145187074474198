import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '..';

export default async function ProjectsLoader() {
	
	const functions = getFunctions(app, 'europe-west3');
	const getAboutMe = httpsCallable(functions, 'getProjects')
	
	const { data } = await getAboutMe();

	return data;
}