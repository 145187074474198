import { useLoaderData } from "react-router";
import AboutMe from "./About/AboutMe";
import AboutMeLoader from "./About/AboutMeLoader";
import EducationLoader from './Education/EducationLoader';
import ProjectsLoader from "./Projects/ProjectsLoader";
import SkillsLoader from "./Skills/SkillsLoader";
import WorkLoader from "./Work/WorkLoader";
import Work from "./Work/Work";
import EducationList from "./Education/EducationList";
import ProjectsList from "./Projects/ProjectsList";
import Skills from "./Skills/Skills";
import WorkList from "./Work/WorkList";
import { useNavigation } from "react-router-dom";

export async function MainLoader() {
	const about = AboutMeLoader();
	const education = EducationLoader();
	const projects = ProjectsLoader();
	const skills = SkillsLoader();
	const work = WorkLoader();

	return Promise.all([about, education, projects, skills, work]).then((values) => {
		return {
			about: values[0],
			education: values[1],
			projects: values[2],
			skills: values[3],
			work: values[4]
		}
	});
}

export default function Main() {

	const { about: { about }, education: { education }, projects: { projects }, skills: { skills }, work: { workExperiences } } = useLoaderData() as any;

	return (
		<div className="container">
			<AboutMe about={about} />
			<Skills skills={skills} />
			<WorkList works={workExperiences} />
			<EducationList educations={education} />
			<ProjectsList projects={projects} />
		</div>
	)
}