import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { functions } from "../..";
import { useLoaderData, useNavigate } from "react-router-dom";
import { parse } from "path";

export default function AddWork() {

	const { work } = useLoaderData() as { work: any };

	const navigate = useNavigate();

	const id = work.id;

	const [title, setTitle] = useState(work?.title || "");
	const [titleLink, setTitleLink] = useState(work?.titleLink || "");
	const [positionTitle, setPositionTitle] = useState(work?.positionTitle || "");
	const [bodyBulletPoints, setBodyBulletPoints] = useState(work?.bodyBulletPoints || []);
	const [skillsList, setSkillsList] = useState(work?.skills || []);
	const [companyName, setCompanyName] = useState(work?.companyName || "");
	const [companyLink, setCompanyLink] = useState(work?.companyLink || "");
	const [durationStart, setDurationStart] = useState(work?.duration.start || 0);
	const [durationEnd, setDurationEnd] = useState(work?.duration.end || 0);
	const [durationCurrent, setDurationCurrent] = useState(work?.duration.current || false);

	function handleTitleChange(e: any) {
		setTitle(e.target.value);
	}

	function handleTitleLinkChange(e: any) {
		setTitleLink(e.target.value);
	}

	function handlePositionTitleChange(e: any) {
		setPositionTitle(e.target.value);
	}

	function hanldeBodyBulletPoint(e: any) {
		const bulletPointsCount = e.target.parentElement.parentElement.childElementCount;
		const bulletPoints: any[] = [];
		for (let i = 0; i < bulletPointsCount; i++) {
			const bulletPoint = e.target.parentElement.parentElement.children[i].children[0].value;
			bulletPoints.push(bulletPoint);
		}
		setBodyBulletPoints(bulletPoints);
	}

	function addBodyBulletPoint() {
		setBodyBulletPoints([...bodyBulletPoints, '']);
	}

	function handleSkillChange(e: any) {
		const skillsCount = e.target.parentElement.parentElement.childElementCount;
		const skills: any[] = [];
		for (let i = 0; i < skillsCount; i++) {
			const skill = e.target.parentElement.parentElement.children[i].children[0].value;
			skills.push(skill);
		}
		setSkillsList(skills);
	}

	function addSkill() {
		setSkillsList([...skillsList, '']);
	}

	function handleCompanyNameChange(e: any) {
		setCompanyName(e.target.value);
	}

	function handleCompanyLinkChange(e: any) {
		setCompanyLink(e.target.value);
	}

	function handleDurationStartChange(e: any) {
		setDurationStart(e.target.valueAsNumber);
	}

	function handleDurationEndChange(e: any) {
		setDurationEnd(e.target.valueAsNumber);
	}

	function handleDurationCurrentChange(e: any) {
		setDurationCurrent(e.target.checked);
	}

	async function handleSubmit(e: any) {
		e.preventDefault();

		try {
			await httpsCallable(functions, work ? 'editWorkExperience' : 'createWorkExperience')({
				id,
				title,
				titleLink,
				positionTitle,
				bodyBulletPoints,
				skills: skillsList,
				company: {
					name: companyName,
					link: companyLink
				},
				duration: {
					start: durationStart,
					end: durationCurrent ? null : durationEnd,
					current: durationCurrent
				}
			});
	
			navigate('/admin/skills');
		} catch(e) {}
	}

	return (
		<div>
			<h1>{work ? "Edit " + work.title : "Add work experience"}</h1>

			<form className="aboutForm" onSubmit={handleSubmit}>
				<div>
					<label htmlFor="title">Title</label>
					<input type="text" name="title" value={title} onChange={handleTitleChange} />
				</div>

				<div>
					<label htmlFor="titleLink">Title Link</label>
					<input type="text" name="titleLink" value={titleLink} onChange={handleTitleLinkChange} />
				</div>

				<div>
					<label htmlFor="positionTitle">Position Title</label>
					<input type="text" name="positionTitle" value={positionTitle} onChange={handlePositionTitleChange} />
				</div>

				<div>
					<label htmlFor="bodyBulletPoints">Body Bullet Points</label>
					<div>
						{bodyBulletPoints.map((bulletPoint: any, index: number) => (
							<div key={index}>
								<input type="text" name="bodyBulletPoints" value={bulletPoint} onChange={hanldeBodyBulletPoint} />
								<span className="spanButton" onClick={() => {
									const newBodyBulletPoints = bodyBulletPoints;
									newBodyBulletPoints.splice(index, 1);
									setBodyBulletPoints([...newBodyBulletPoints]);
								}}>Delete</span>
							</div>
						))}
					</div>
					<span className="spanButton" onClick={addBodyBulletPoint}>Add</span>
				</div>

				<div>
					<label htmlFor="skills">Skills</label>
					<div>
						{skillsList.map((skill: any, index: number) => (
							<div key={index}>
								<input type="text" name="skills" value={skill} onChange={handleSkillChange} />
								<span className="spanButton" onClick={() => {
									const newSkillsList = skillsList;
									newSkillsList.splice(index, 1);
									setSkillsList([...newSkillsList]);
								}}>Delete</span>
							</div>
						))}
					</div>
					<span className="spanButton" onClick={addSkill}>Add</span>
				</div>

				<div>
					<label htmlFor="companyName">Company Name</label>
					<input type="text" name="companyName" value={companyName} onChange={handleCompanyNameChange} />
				</div>

				<div>
					<label htmlFor="companyLink">Company Link</label>
					<input type="text" name="companyLink" value={companyLink} onChange={handleCompanyLinkChange} />
				</div>

				<div>
					<label htmlFor="durationStart">Duration Start</label>
					<input type="date" name="durationStart" value={msToYMD(durationStart)} onChange={handleDurationStartChange} />
				</div>

				<div>
					<label htmlFor="durationEnd">Duration End</label>
					<input type="date" name="durationEnd" value={msToYMD(durationEnd)} onChange={handleDurationEndChange} />
				</div>

				<div>
					<label htmlFor="durationCurrent">Duration Current</label>
					<input type="checkbox" name="durationCurrent" checked={durationCurrent} onChange={handleDurationCurrentChange} />
				</div>

				<input className="submitButton" type="submit" value={work ? "Update" : "Add"} />
			</form>
				
		</div>
	)
}

function msToYMD(ms: number) {
	const date = new Date(ms);
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();

	return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}