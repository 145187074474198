import "./Skill.css"

export default function Skills({ skills }: { skills: any }) {
	return (
		<div className="innerContainer">
			<h1>Skills and technologies</h1>

			<div className="skillContainer">
				{skills.map((skill: any) => {
					return (
						<div className="tooltip">
							{skill.skill}
							<span className="tooltipText">{skill.years} years | {skill.proficiency} %</span>
						</div>
					)
				})}
			</div>
		</div>
	)
}