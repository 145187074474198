import { useState } from "react";
import { useLoaderData } from "react-router";
import { httpsCallable } from "firebase/functions";
import { functions } from "../..";

export default function AboutAdmin() {
	const { about } = useLoaderData() as any;

	const [firstName, setFirstName] = useState(about.name.firstName);
	const [middleName, setMiddleName] = useState(about.name.middleName);
	const [lastName, setLastName] = useState(about.name.lastName);
	const [nickname, setNickname] = useState(about.name.nickname);


	const [birthdate, setBirthdate] = useState(about.birthdate);
	const [role, setRole] = useState(about.role);
	const [aboutMe, setAboutMe] = useState(about.aboutMe);
	const [experiences, setExperiences] = useState(about.experiences as string[]);

	function handleFirstNameChange(e: any) {
		setFirstName(e.target.value);
	}
	function handleMiddleNameChange(e: any) {
		setMiddleName(e.target.value);
	}
	function handleLastNameChange(e: any) {
		setLastName(e.target.value);
	}
	function handleNicknameChange(e: any) {
		setNickname(e.target.value);
	}

	function handleBirthdateChange(e: any) {
		setBirthdate(e.target.valueAsNumber);
	}

	function handleRoleChange(e: any) {
		setRole(e.target.value);
	}

	function handleAboutMeChange(e: any) {
		setAboutMe(e.target.value);
	}

	function handleExperiencesChange(e: any) {
		// Get the amount of experiences from the count of inputs
		const experienceCount = e.target.parentElement.parentElement.childElementCount;
		const experiences: any[] = [];
		for (let i = 0; i < experienceCount; i++) {
			const experience = e.target.parentElement.parentElement.children[i].children[0].value;
			experiences.push(experience);
		}
		setExperiences(experiences);
	}

	function addExperience() {
		setExperiences([...experiences, '']);
	}

	async function handleSubmit(e: any) {

		e.preventDefault();

		await httpsCallable(functions, 'editAbout')({
			name: {
				firstName,
				middleName,
				lastName,
				nickname
			},
			birthdate: birthdate,
			role,
			aboutMe,
			experiences
		});
	}

	return (
		<div>
			<form className="aboutForm" onSubmit={handleSubmit}>
			
				<div>
					<label htmlFor="firstName">First Name</label>
					<input type="text" name="firstName" value={firstName} onChange={handleFirstNameChange} />
				</div>

				<div>
					<label htmlFor="middleName">Middle Name</label>
					<input type="text" name="middleName" value={middleName} onChange={handleMiddleNameChange} />
				</div>

				<div>
					<label htmlFor="lastName">Last Name</label>
					<input type="text" name="lastName" value={lastName} onChange={handleLastNameChange} />
				</div>

				<div>
					<label htmlFor="nickname">Nickname</label>
					<input type="text" name="nickname" value={nickname} onChange={handleNicknameChange} />
				</div>

				<div>
					<label htmlFor="birthdate">Birthdate</label>
					<input type="date" name="birthdate" value={msToYMD(birthdate)} onChange={handleBirthdateChange} />
				</div>

				<div>
					<label htmlFor="role">Role</label>
					<input type="text" name="role" value={role} onChange={handleRoleChange} />
				</div>

				<div>
					<label htmlFor="aboutMe">About Me</label>
					<textarea name="aboutMe" value={aboutMe} onChange={handleAboutMeChange} />
				</div>

				<div>
					<label htmlFor="experiences">Experiences</label>
					<div onClick={addExperience}>Add experience</div>
				</div>

				<div style={{display: 'flex', flexFlow: 'column'}}>
					{experiences.map((experience, index) => {
						return (
							<div>
								<input type="text" name="experiences" value={experience} onChange={handleExperiencesChange} key={index} />
								<span onClick={() => {
									const newExperiences = [...experiences];
									newExperiences.splice(index, 1);
									setExperiences(newExperiences);
								}}>Delete</span>
							</div>
						);
					})}
				</div>

				<input className="submitButton" type="submit" value="Submit" />
			</form>
		</div>
	);
}

// Milliseconds to YYYY-MM-DD
// Include leading zeros
function msToYMD(ms: number) {
	const date = new Date(ms);
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();

	return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}