import { useState } from "react";
import "./Contact.css"
import { Link, useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions } from "..";

export default function ContactForm() {

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');

	const navigate = useNavigate();

	function handleNameChange(e: any) {
		setName(e.target.value);
	}

	function handleEmailChange(e: any) {
		setEmail(e.target.value);
	}

	function handleMessageChange(e: any) {
		setMessage(e.target.value);
	}

	async function handleSubmit(e: any) {
		e.preventDefault();

		setSuccessMessage('');

		if (!name || !email || !message) {
			setErrorMessage('Please fill in all fields.');
			setSuccessMessage('');
			return;
		}

		const data = {
			name,
			email,
			message
		};

		try {
			await httpsCallable(functions, 'createNewRequest')(data);
			setSuccessMessage('Your message has been sent successfully! You will receive a confirmation email shortly. If you do not receive an email, please check your spam folder or contact me directly via the link below.');
			setErrorMessage('');
			setName('');
			setEmail('');
			setMessage('');
		} catch(e) {
			setErrorMessage("Something went wrong. Make sure you have filled in all fields correctly and you are connected to the internet. If the problem persists, please contact me directly via the link below.");
			setSuccessMessage('');
			return;
		}
	}
	
	return (
		<div className="container">
			<div className="innerContainer">
				<h1>Contact</h1>
				<form onSubmit={handleSubmit}>

					<div className="form-group">
						{errorMessage && <p className="error">{errorMessage}</p>}
						{successMessage && <p className="success">{successMessage}</p>}
					</div>

					<div className="form-group">
						<span>
							<label htmlFor="name">Name</label>
							<input placeholder="Name" type="text" name="name" value={name} onChange={handleNameChange} />
						</span>
						<span className="spacer"></span>
						<span>
							<label htmlFor="email">Email</label>
							<input placeholder="E-Mail" type="email" name="email" value={email} onChange={handleEmailChange} />
						</span>
					</div>

					<div className="form-group">
						<label htmlFor="message">Message</label>
						<textarea placeholder="Your message" name="message" value={message} onChange={handleMessageChange} />
					</div>

					<div className="form-group">
						<button className="button primary" type="submit">Submit</button>
						<Link style={{marginLeft: '10px'}} className="button secondary" to="/">Back</Link>
					</div>

					<div className="form-group">
						<p style={{fontSize: '0.9rem'}}>After submitting you will receive a confirmation email from: <a href="mailto:david.neidhart@xndr.tech">david.neidhart@xndr.tech</a></p>
					</div>
				</form>
			</div>
		</div>
	)
}