import Work from "./Work"

export default function WorkList({ works }: { works: any }) {
	return (
		<div className="innerContainer">
			<h1>Experience</h1>

			<div className="experienceContainer">
				{works.map((work: any) => {
					return (
						<Work work={work} />
					)
				})}
			</div>
		</div>
	)
}