import { formatDate } from ".."
import { BulletPointList } from "../BulletPoint"
import "./Project.css"

export default function Project({ project }: { project: any }) {

	return (
		<div className="project">
			<h3>{project.titleLink ? <a href={project.titleLink}>{project.title}</a> : <>{project.title}</>} | {project.type}</h3>
			<h6><span className="experienceTime">{formatDate(project.when)}</span></h6>

			<BulletPointList bulletPoints={project.bodyBulletPoints} />

			{
				project.skills.length > 0 && (
					<>
						<div className="categoryTitle">Skills</div>
						<div className="projectFlex">
							{
								project.skills.map((skill: any) => {
									return (
										<div className="flexChild">{skill}</div>
									)
								})
							}
						</div>
					</>
				)
			}

			{
				project.links.length > 0 && (
					<>
						<div className="categoryTitle">Links</div>
						<div className="projectFlex">
							{
								project.links.map((link: any) => {
									return (
										<div className="flexChild"><a href={link.link}>{link.name}</a></div>
									)
								})
							}
						</div>
					</>
				)
			}
		</div>
	)
}