import { getAuth } from 'firebase/auth';
import { app, auth } from '..';
import { useAuthState } from 'react-firebase-hooks/auth';
import LoginForm from './LoginForm';
import AdminHomepage from './Homepage/Homepage';

export default function Admin() {

	const [user, loading, error] = useAuthState(auth);

	if (loading) {
		return (
			<div style={{height: "100vh", width: "100vw"}}><h1>Loading</h1></div>
		)
	} else if (error) {
		return (
			<div style={{height: "100vh", width: "100vw"}}><h1>Error</h1></div>
		)
	} else if (user) {
		return (
			<AdminHomepage />
		)
	} else {
		return (
			<LoginForm />
		)
	}
}