interface BulletPointProps {
	text: string;
	index: number;
	subPoints?: BulletPointProps[];
}

export function BulletPoint({ text, index, subPoints }: BulletPointProps) {

	return (
		<li key={index}>
			{text}
			<BulletPointList bulletPoints={subPoints ?? []} />
		</li>
	)
}

export function BulletPointList({ bulletPoints }: { bulletPoints: BulletPointProps[] }) {

	return (
		<ul className="mainUl">
			{
				bulletPoints.map((bulletPoint: BulletPointProps) => {
					return (
						<BulletPoint text={bulletPoint.text} index={bulletPoint.index} subPoints={bulletPoint.subPoints} />
					)
				})
			}
		</ul>
	)

}