import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { initializeApp } from "firebase/app";
import { firebaseConfig } from './firebase';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './errorPage/ErrorPage';
import Main, { MainLoader } from './Main';
import Admin from './Admin/Admin';
import LinkMenu from './SocialLinks/LinkMenu';
import SocialLinksLoader from './SocialLinks/SocialLinksLoader';
import Index from './IndexPage';
import { getAuth } from '@firebase/auth';
import AboutMeLoader from './About/AboutMeLoader';
import { getFunctions } from 'firebase/functions';
import AboutAdmin from './Admin/about/AboutAdmin';
import ContactForm from './Contact/ContactForm';
import SkillsLoader from './Skills/SkillsLoader';
import SkillsAdmin from './Admin/skills/SkillsAdmin';
import AddSkill from './Admin/skills/AddSkill';
import WorkAdmin from './Admin/work/WorkAdmin';
import WorkLoader from './Work/WorkLoader';
import AddWork from './Admin/work/AddWork';
import EducationAdmin from './Admin/education/EducationAdmin';
import EducationLoader from './Education/EducationLoader';
import AddEducation from './Admin/education/AddEducation';
import { Loader } from './Loader';

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app, 'europe-west3');

const router = createBrowserRouter([
  {
    path: '/',
    element: <Index />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <LinkMenu />,
        errorElement: <ErrorPage />,
        loader: SocialLinksLoader,
        children: [
          {
            path: '/',
            element: <Main />,
            errorElement: <ErrorPage />,
            loader: MainLoader
          }
        ]
      }
    ]
  },
  {
    path: '/contact',
    element: <ContactForm />,
    errorElement: <ErrorPage />
  },
  {
    path: '/admin',
    element: <Admin />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'about',
        element: <AboutAdmin />,
        loader: AboutMeLoader
      },
      {
        path: 'skills/add',
        element: <AddSkill />
      },
      {
        path: 'skills/:skill',
        element: <AddSkill />,
        loader: SkillsLoader
      },
      {
        path: 'skills',
        element: <SkillsAdmin />,
        loader: SkillsLoader
      },
      {
        path: 'work',
        element: <WorkAdmin />,
        loader: WorkLoader
      },
      {
        path: 'work/add',
        element: <AddWork />
      },
      {
        path: 'work/:work',
        element: <AddWork />,
        loader: WorkLoader
      },
      {
        path: 'education',
        element: <EducationAdmin />,
        loader: EducationLoader
      },
      {
        path: 'education/add',
        element: <AddEducation />,
      },
      {
        path: 'education/:education',
        element: <AddEducation />,
        loader: EducationLoader
      },
      {
        path: 'projects',
        element: <>Projects</>
      }
    ]
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} fallbackElement={<Loader />} />
  </React.StrictMode>
);

export function formatDate(timestamp: number) {
	const date = new Date(timestamp);
	const month = date.toLocaleString('default', { month: 'short' });
	const year = date.getFullYear();

	return `${month} ${year}`;
}