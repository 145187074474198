import { Auth, signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { auth } from '..';

export default function LoginForm() {

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	function handleEmailChange(e: any) {
		setEmail(e.target.value);
	}

	function handlePasswordChange(e: any) {
		setPassword(e.target.value);
	}

	function handleSubmit(e: any) {
		e.preventDefault();

		signInWithEmailAndPassword(auth, email, password)
	}
	
	return (
		<div style={{width: '100vw', height: '100vh'}}>
			<form onSubmit={handleSubmit}>
				<input style={{display: 'block'}} type="email" value={email} onChange={handleEmailChange} />

				<input style={{display: 'block'}} type="password" value={password} onChange={handlePasswordChange} />

				<input style={{display: 'block'}} type="submit" value="Login" />
			</form>
		</div>
	)
}