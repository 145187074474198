import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { functions } from "../..";
import { useLoaderData, useNavigate } from "react-router-dom";
import { parse } from "path";
import BulletPoint from "../../BodyBulletPoints/BulletPoint";

export default function AddEducation() {

	const { education } = useLoaderData() as { education: any };

	const navigate = useNavigate();

	const id = education.id;

	const [title, setTitle] = useState(education?.title || "");
	const [titleLink, setTitleLink] = useState(education?.titleLink || "");
	const [bodyBulletPoints, setBodyBulletPoints] = useState(education?.bodyBulletPoints || []);
	const [institutionName, setInstitutionName] = useState(education?.institution.name || "");
	const [institutionLink, setInstitutionLink] = useState(education?.institution.link || "");
	const [graduationGrade, setGraduationGrade] = useState(education?.graduation.grade || "0,0");
	const [graduationTitle, setGraduationTitle] = useState(education?.graduation.title || "");
	const [durationStart, setDurationStart] = useState(education?.duration.start._seconds * 1000 || 0);
	const [durationEnd, setDurationEnd] = useState(education?.duration.end._seconds * 1000 || 0);
	const [durationCurrent, setDurationCurrent] = useState(education?.duration.current || false);

	function handleTitleChange(e: any) {
		setTitle(e.target.value);
	}

	function handleTitleLinkChange(e: any) {
		setTitleLink(e.target.value);
	}

	function handleBodyBulletPoint(text: any, index: any) {
		const newBodyBulletPoints = bodyBulletPoints as any[];
		newBodyBulletPoints[newBodyBulletPoints.findIndex((v, i) => v.index === index)] = text;
		setBodyBulletPoints([...newBodyBulletPoints]);
	}

	function addBodyBulletPoint() {
		setBodyBulletPoints([...bodyBulletPoints, { text: "", index: `${bodyBulletPoints.filter((v: any) => !v.index.includes('.')).length}` }]);
	}

	function deleteBodyBulletPoint() {
		
	}

	function handleInsitutionNameChange(e: any) {
		setInstitutionName(e.target.value);
	}

	function handleInstitutionLinkChange(e: any) {
		setInstitutionLink(e.target.value);
	}

	function handleGraduationGradeChange(e: any) {
		setGraduationGrade(e.target.value);
	}

	function handleGraduationTitleChange(e: any) {
		setGraduationTitle(e.target.value);
	}

	function handleDurationStartChange(e: any) {
		setDurationStart(e.target.valueAsNumber);
	}

	function handleDurationEndChange(e: any) {
		setDurationEnd(e.target.valueAsNumber);
	}

	function handleDurationCurrentChange(e: any) {
		setDurationCurrent(e.target.checked);
	}

	async function handleSubmit(e: any) {
		e.preventDefault();

		try {
			await httpsCallable(functions, education ? 'editEducation' : 'createEducation')({
				id,
				title,
				titleLink,
				bodyBulletPoints: bodyBulletPoints.filter((bulletPoint: any) => bulletPoint !== ""),
				institution: {
					name: institutionName,
					link: institutionLink
				},
				duration: {
					start: durationStart,
					end: durationCurrent ? null : durationEnd,
					current: durationCurrent
				}
			});
	
			navigate('/admin/education');
		} catch(e) {}
	}

	return (
		<div>
			<h1>{education ? "Edit " + education.title : "Add education"}</h1>

			<form className="aboutForm" onSubmit={handleSubmit}>
				<div>
					<label htmlFor="title">Title</label>
					<input type="text" name="title" value={title} onChange={handleTitleChange} />
				</div>

				<div>
					<label htmlFor="titleLink">Title Link</label>
					<input type="text" name="titleLink" value={titleLink} onChange={handleTitleLinkChange} />
				</div>

				<div>
					<label htmlFor="bodyBulletPoints">Body Bullet Points</label>
					<div>
						{bodyBulletPoints.map((bulletPoint: any) => (
							<>Test</>
						))}
					</div>
					<span className="spanButton" onClick={addBodyBulletPoint}>Add</span>
				</div>

				<div>
					<label htmlFor="institutionName">Institution Name</label>
					<input type="text" name="institutionName" value={institutionName} onChange={handleInsitutionNameChange} />
				</div>

				<div>
					<label htmlFor="institutionLink">Institution Link</label>
					<input type="text" name="institutionLink" value={institutionLink} onChange={handleInstitutionLinkChange} />
				</div>

				<div>
					<label htmlFor="graduationTitle">Graduation Title</label>
					<input type="text" name="graduationTitle" value={graduationTitle} onChange={handleGraduationTitleChange} />
				</div>

				<div>
					<label htmlFor="graduationGrade">Graduation Grade</label>
					<input type="text" name="graduationGrade" value={graduationGrade} onChange={handleGraduationGradeChange} />
				</div>

				<div>
					<label htmlFor="durationStart">Duration Start</label>
					<input type="date" name="durationStart" value={msToYMD(durationStart)} onChange={handleDurationStartChange} />
				</div>

				<div>
					<label htmlFor="durationEnd">Duration End</label>
					<input type="date" name="durationEnd" value={msToYMD(durationEnd)} onChange={handleDurationEndChange} />
				</div>

				<div>
					<label htmlFor="durationCurrent">Duration Current</label>
					<input type="checkbox" name="durationCurrent" checked={durationCurrent} onChange={handleDurationCurrentChange} />
				</div>

				<input className="submitButton" type="submit" value={education ? "Update" : "Add"} />
			</form>
				
		</div>
	)
}

function msToYMD(ms: number) {
	const date = new Date(ms);
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();

	return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}