import { Link, useLoaderData } from "react-router-dom";
import { functions } from "../..";
import { httpsCallable } from "firebase/functions";

export default function WorkAdmin() {

	const { workExperiences: work } = useLoaderData() as { workExperiences: any[] };

	async function deleteWork(work: string) {
		await httpsCallable(functions, 'deleteWorkExperience')({
			workId: work
		});
	}

	return (
		<div className="container">
			<h1>Work</h1>

			<Link className="spanButton" to="/admin/work/add">Add work experience</Link>

			<table>
				<thead>
					<tr>
						<th>Title</th>
						<th>Company</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{work.map((work: any) => (
						<tr key={work.id}>
							<td>{work.title}</td>
							<td>{work.company.name}</td>
							<td>
								<a className="spanButton" onClick={() => deleteWork(work.id)}>Delete</a>
								<Link className="spanButton" to={`/admin/work/${work.id}`}>Edit</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}