import reactStringReplace from 'react-string-replace';

const greetings = [ "Hi", "Hello", "Hey", "Greetings", "Yo", "Sup" ]

export default function AboutMe({ about }: { about: any }) {
	
	const aboutMe = replaceStrings(about.aboutMe, [ '{firstName}', '{role}', '{lastName}', '{age}', '{insertExperiences}' ], (replacee: string) => {
		switch (replacee) {
			case '{firstName}':
				return <span style={{"fontWeight": "bold"}}>{about.name.firstName}</span>;
			case '{lastName}':
				return <span style={{"fontWeight": "bold"}}>{about.name.lastName}</span>;
			case '{age}':
				return <span>{about.age}</span>;
			case '{insertExperiences}':
				return <ul className='mainUl'>{about.experiences.map((experience: any) => {
					return <li>{experience}</li>;
				})}</ul>;
			case '{role}':
				return <span>{about.role}</span>;
			default:
				return <></>;
		}
	});

	const greeting = greetings[Math.floor(Math.random() * greetings.length)];

	return (
		<div className='innerContainer'>
			<h1>{greeting}!</h1>
			<p>{aboutMe}</p>
		</div>
	)
}

function replaceStrings(text: string, replacees: string[], replacer: (replacee: string) => JSX.Element) {
	let result: any = text;

	replacees.forEach(replacee => {
		result = reactStringReplace(result, replacee, (match: string, i: number) => {
			return replacer(match);
		});
	});

	return result;
}