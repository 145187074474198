import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '..';

export default async function EducationLoader(options?: { params?: any }) {
	
	if (options?.params.education) {
		const functions = getFunctions(app, 'europe-west3');
		const getAboutMe = httpsCallable(functions, 'getEducation')
		
		const { data } = await getAboutMe({ educationId: options.params.education });

		return data;
	}

	const functions = getFunctions(app, 'europe-west3');
	const getAboutMe = httpsCallable(functions, 'getEducations')
	
	const { data } = await getAboutMe();

	return data;
}