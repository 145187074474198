// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDIdZh-GPySqR--0XUEadNQ0Fva92G2O-U",
  authDomain: "portfolio-98ab6.firebaseapp.com",
  projectId: "portfolio-98ab6",
  storageBucket: "portfolio-98ab6.appspot.com",
  messagingSenderId: "145780507894",
  appId: "1:145780507894:web:d47b8fef111e804a8e58d7",
  measurementId: "G-5R3RFYE7SX"
};