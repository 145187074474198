import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { functions } from "../..";
import { useLoaderData, useNavigate } from "react-router-dom";

export default function AddSkill() {

	const loaderData = useLoaderData() as any;

	const { skill } = loaderData?.skill ? loaderData : { skill: null };

	console.log(skill);

	const [skillName, setSkillName] = useState(skill?.skill || "");
	const [proficiency, setProficiency] = useState(skill?.proficiency || 0);
	const [yearsOfExperience, setYearsOfExperience] = useState(skill?.years || 0);

	const navigate = useNavigate();

	function handleSkillNameChange(e: any) {
		setSkillName(e.target.value);
	}

	function handleProficiencyChange(e: any) {
		setProficiency(e.target.value);
	}

	function handleYearsOfExperienceChange(e: any) {
		setYearsOfExperience(e.target.value);
	}

	async function handleSubmit(e: any) {
		e.preventDefault();

		

		try {
			await httpsCallable(functions, skill ? 'editSkill' : 'createSkill')({
				skill: skillName,
				proficiency: parseInt(proficiency),
				years: parseInt(yearsOfExperience)
			});
	
			navigate('/admin/skills');
		} catch(e) {}
	}

	return (
		<div>
			<h1>{skill ? "Edit " + skill.skill : "Add skill"}</h1>

			<form className="aboutForm" onSubmit={handleSubmit}>
				<div>
					<label htmlFor="skill">Skill</label>
					<input type="text" name="skill" value={skillName} onChange={handleSkillNameChange} />
				</div>

				<div>
					<label htmlFor="proficiency">Proficiency</label>
					<input type="number" name="proficiency" value={proficiency} onChange={handleProficiencyChange} />
				</div>

				<div>
					<label htmlFor="yearsOfExperience">Years of Experience</label>
					<input type="number" name="yearsOfExperience" value={yearsOfExperience} onChange={handleYearsOfExperienceChange} />
				</div>

				<input className="submitButton" type="submit" value={skill ? "Update" : "Add"} />
			</form>
				
		</div>
	)
}