import { Outlet } from "react-router";
import Sidebar from "../Sidebar/Sidebar";
import "./Homepage.css"

export default function AdminHomepage() {

	return (
		<>
			<Sidebar />

			<div className="mainContent">
				<Outlet />
			</div>
		</>
	)

}