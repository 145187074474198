import Project from "./Project"

export default function ProjectsList({ projects }: { projects: any }) {
	return (
		<div className="innerContainer">
			<h1>Projects</h1>

			<div className="projectContainer">
				{projects.map((project: any) => {
					return (
						<Project project={project} />
					)
				})}
			</div>
		</div>
	)
}