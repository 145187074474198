import { Outlet, useLoaderData } from "react-router";
import "./Menu.css"

export default function LinkMenu() {

	const { socials } = useLoaderData() as any;
	
	return (
		<div>
			<div className="menuContainer">
				<div className="menuInner menuInnerLeft">
					{socials.map((link: any) => {
						return (
							<a className="menuLink" key={link.link} href={link.link} target="_blank">{link.name}</a>
						)
					})}
				</div>
			</div>

			<Outlet />
		</div>
	)
}